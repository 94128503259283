import React from "react";
import { graphql } from "gatsby";
import get from "lodash/get";
import Seo from "../components/seo";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Layout from "../components/layout";
import PageHero from "../components/page-hero";
import SidebarContactForm from "../components/sidebar-contact-form";

class PrivacyPolicyPage extends React.Component {
  render() {
    const page = get(this, "props.data.contentfulPage");

    return (
      <Layout location={this.props.location}>
        <Seo
          title={`${page.title}`}
          keywords={`${page.keywords ? page.keywords.join() : ""}`}
          description={page.seoDescription}
        />
        <PageHero image={page.backgroundImage}>
          <div className="is-inline-block has-background-primary">
            <h1 className={"title has-text-white-bis pl-3 pr-4 pt-2 pb-2"}>
              {page.title}
            </h1>
          </div>
        </PageHero>
        <div className="columns p-4">
          <div className="column">
            <div className="content">{renderRichText(page.body)}</div>

            <div className="content">
              {page.footer !== null && renderRichText(page.footer)}
            </div>
          </div>
          <div className="column is-one-third">
            <SidebarContactForm />
          </div>
        </div>
      </Layout>
    );
  }
}

export default PrivacyPolicyPage;

export const pageQuery = graphql`
  query PrivacyPolicyPageQuery {
    contentfulPage(slug: { eq: "privacy-policy" }) {
      slug
      title
      keywords
      seoDescription
      body {
        raw
      }
      footer {
        raw
      }
      backgroundImage: image {
        title
        gatsbyImageData(
          layout: FULL_WIDTH
          resizingBehavior: SCALE
          placeholder: BLURRED
        )
      }
    }
  }
`;
